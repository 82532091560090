<!-- 选择车型 -->
<template>
  <div class="CarSelect">
    <!-- 索引列表 -->
    <van-index-bar :index-list="[]" :sticky-offset-top="44">
      <template v-for="(ele, key) in carList">
        <van-index-anchor :key="key" :index="`${key.substr(1)}款`" />
        <van-cell
          v-for="item in ele"
          :key="item.styleId"
          :title="item.styleName"
          :value="item.styleReferPrice"
          @click="nextPage(item)"
        >
          <template #right-icon>
            <span v-show="item.styleReferPrice">万</span>
          </template>
        </van-cell>
      </template>
    </van-index-bar>
  </div>
</template>

<script>
import { getStyleList } from "@/api/api_carInstallment";
export default {
  name: "CarSelect",
  data() {
    return {
      carList: [],
      serialId: this.$route.query.serialId,
      cityId: this.$route.query.cityId
    };
  },
  created() {
    this.getList();
  },
  methods: {
    nextPage(item) {
      this.$router.replace({
        name: "CarDetail",
        query: { serialId: item.serialId, styleId: item.styleId }
      });
    },
    getList() {
      getStyleList({ cityId: this.cityId, serialId: this.serialId }).then(
        res => {
          if (res.code === 0) {
            const ls = this.$tools.handleArrayClassify(
              res.data.info && res.data.info.list,
              "year"
            );
            this.carList = ls.arr;
          }
        }
      );
    }
  }
};
</script>
<style lang="scss" scoped>
.CarSelect {
  .van-cell__value {
    color: $mainRed;
  }
}
</style>
